import { observer } from 'mobx-react-lite';
import React from 'react';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';

import cn from './style.module.sass';

// Дубляж, убрать при получении данных с сервака

interface IProps {
    filterElement: ICalculatorFilterElement;
    handleChange: (number) => void;
    callback?: () => void;
}

const PipNew: React.FC<IProps> = observer(({ filterElement, handleChange, callback }) => {
    const { percent, label, value } = filterElement;

    const formatStyle = () => {
        if (percent === 0) return { left: '0%' };
        if (percent === 100) return { right: '0%' };
        return { left: `${percent}%` };
    };

    const onClick = () => {
        handleChange(value);
        if (callback !== undefined) {
            callback();
        }
    };

    return (
        <div role="presentation" onClick={onClick} className={cn.pip} style={formatStyle()}>
            <div className={cn.label}>{label}</div>
        </div>
    );
});

export default PipNew;
