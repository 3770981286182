import clsx from 'clsx';
import { toPng } from 'html-to-image';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';

import HypothecCalculatorChartWrapper from '@/components/blocks/calculators/layouts/HypothecCalculator/ChartWrapper';
import DownloadChart from '@/components/blocks/calculators/layouts/HypothecCalculator/DownloadChart';
import HypothecCalculatorInfo from '@/components/blocks/calculators/layouts/HypothecCalculator/Info';
import HypothecCalculatorMonthInfo from '@/components/blocks/calculators/layouts/HypothecCalculator/MonthInfo';
import HypothecCalculatorSpecial from '@/components/blocks/calculators/layouts/HypothecCalculator/Special';
import IconDownload from '@/components/UI/IconDownload';
import PreloaderBlocks from '@/components/UI/PreloaderBlocks';
import { THypothecCalculatorConfigItem } from '@/types/calculator/hypothec';

import cn from './style.module.sass';

interface IProps {
    currentConfig: THypothecCalculatorConfigItem;
}

function HypothecCalculatorModalContent({ currentConfig }: IProps) {
    const ref = useRef<HTMLDivElement>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClick = useCallback(() => {
        if (ref.current === null) {
            return;
        }

        const filter = (node: HTMLElement) => {
            const exclusionClasses = ['secret-div'];
            return !exclusionClasses.some(classname => node.classList?.contains(classname));
        };

        toPng(ref.current, {
            cacheBust: true,
            filter,
            backgroundColor: '#ffffff',
            style: { padding: '20px' },
        })
            .then(dataUrl => {
                const link = document.createElement('a');
                link.download = 'payment-schedule.png';
                link.href = dataUrl;
                link.click();
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            });
    }, [ref]);

    return (
        <div className={cn.wrapper}>
            {isLoading && (
                <div className={cn.loadWrapper}>
                    <PreloaderBlocks />
                </div>
            )}

            <div className={clsx(cn.downloadWrap)}>
                <div className={clsx(cn.downloadWrapper)} ref={ref}>
                    <DownloadChart currentConfig={currentConfig} />
                </div>
            </div>

            <div className={cn.head}>
                <div className={cn.headTitle}>График платежей</div>
                <div
                    role="presentation"
                    className={cn.headButton}
                    onClick={() => {
                        setIsLoading(true);
                        handleClick();
                    }}
                >
                    <IconDownload /> <span className={cn.headButtonText}>Скачать график</span>
                </div>
            </div>

            <div className={cn.innerWrapper}>
                <HypothecCalculatorInfo currentConfig={currentConfig} />

                <div className={clsx(cn.monthInfo)}>
                    <HypothecCalculatorMonthInfo currentConfig={currentConfig} />
                </div>

                <div className={clsx(cn.special)}>
                    <HypothecCalculatorSpecial currentConfig={currentConfig} />
                </div>

                <div className={cn.chart}>
                    <HypothecCalculatorChartWrapper />
                </div>
            </div>
        </div>
    );
}

export default observer(HypothecCalculatorModalContent);
