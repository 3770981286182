import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import FormedRangeNew from '@/components/blocks/calculators/range/FormedRange';
import Input from '@/components/UI/Input';
import { IRangeInput } from '@/domain/calculator/blocksStore/Range';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    gtag?: GTagEvent;
    config: IRangeInput;
    callback?: () => void;
    customClass?: string;
    noMaxWidth?: boolean;
    isPencilNeeded?: boolean;
    minValue?: number;
    maxValue?: number;
    isRedesign?: boolean;
}
const RangeInput: React.FC<IProps> = observer(
    ({ gtag, config, noMaxWidth, callback, customClass, isPencilNeeded = false, minValue, maxValue, isRedesign }) => {
        const { handleChangeValue, label, formatValue, handleChangeFocus, isFocus, value, title } = config;

        const [inputValue, setInputValue] = useState(value?.toString());

        const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            const numberRegex = /^\d*$/;

            if (!numberRegex.test(e.target.value)) return;

            const stringWOLeadingZeros = e.target.value.replace(/^0+/, '');

            setInputValue(stringWOLeadingZeros);
        };

        const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            handleChangeFocus(false);
            handleChangeValue(inputValue);

            if (callback !== undefined) {
                callback();
            }
        };

        const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            handleChangeFocus(true);
        };

        const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                handleChangeFocus(false);
                handleChangeValue(+inputValue);

                if (callback !== undefined) {
                    callback();
                }

                (e.target as HTMLInputElement).blur();
            }
        };

        useEffect(() => {
            setInputValue(value?.toString());
        }, [value]);

        useEffect(() => {
            if (!isFocus) {
                setInputValue(value?.toString());
                handleChangeValue(+value);
            }
        }, [isFocus]);

        return (
            <div className={clsx(cn.wrapper, noMaxWidth ? cn.noMaxWidth : '')}>
                {title && <div className={cn.label}>{title}</div>}
                <Input
                    value={`${isFocus ? inputValue : formatValue}`}
                    label={label}
                    customClass={clsx(
                        customClass,
                        noMaxWidth && cn.noMaxWidth,
                        noMaxWidth && 'noMaxWidth',
                        isPencilNeeded && cn.pencil
                    )}
                    onChangeHandler={onChangeHandler}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    isRedesign={isRedesign}
                />
                <FormedRangeNew
                    config={config}
                    gtag={gtag}
                    callback={callback}
                    minValue={minValue}
                    maxValue={maxValue}
                    isRedesign={isRedesign}
                />
            </div>
        );
    }
);

export default RangeInput;
