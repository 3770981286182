import { makeAutoObservable } from 'mobx';

import { Cookie } from '@/utils/cookie';

export type TRangeConfig = {
    name: string;
    elements: IElement[];
    initValue: number;
    minValue?: number;
    maxValue?: number;
    label?: string;
    title?: string;
    customFormatValueHandle?: (value: string | number, symbol?: string) => string;
};

export type IElement = {
    value: number;
    step: number;
    percent: number;
    label: string;
    id: number;
};

type IRangeSliderData = {
    [keys: string]: [IValue, IStep];
};

type IValue = number;
type IStep = number;

export interface IRangeInput {
    name: string;
    elements: IElement[];
    value: number;
    elementsSlider: IRangeSliderData;
    label: string;
    initValue: number;
    maxValue: number;
    minValue: number;
    title?: string;
    formatValue: string;
    isFocus: boolean;
    getArrayRange: (elements: IElement[]) => IRangeSliderData;
    handleChangeValue: (value: string | number) => void;
    currentValue: (value: string) => number;
    getMaxValue: (elements: IElement[]) => void;
    getMinValue: (elements: IElement[]) => void;
    handleChangeConfig: ({ elements }: { elements: IElement[] }) => void;
    handleChangeMinValue: ({ value }: { value: number }) => void;
    handleChangeMaxValue: ({ value }: { value: number }) => void;
    formatValueHandle: (value: string | number, symbol?: string) => string;
    handleChangeFocus: (status: boolean) => void;
    syncWithCookies?: boolean;
}

class RangeInput implements IRangeInput {
    name;

    elements;

    value;

    elementsSlider;

    maxValue = 0;

    minValue = 0;

    label;

    initValue;

    formatValue;

    isFocus = false;

    syncWithCookies = false;

    // Отключить установку выбранного значения калькулятора в фрейм
    disableFrameChange = false;

    title;

    constructor(config: TRangeConfig, disableFrameChange?: boolean, syncWithCookies?: boolean) {
        const { initValue, elements, name, label, title, customFormatValueHandle } = config;

        if (!elements) return;

        this.maxValue = this.getMaxValue(elements) || 0;
        this.minValue = this.getMinValue(elements) || 0;
        this.syncWithCookies = syncWithCookies;

        const correctInitValue = syncWithCookies ? this.getInitValueWithCookie(initValue) : initValue;
        this.name = name;
        this.label = label;
        this.title = title;
        this.elements = elements;
        this.initValue = correctInitValue;
        this.value = correctInitValue;
        this.disableFrameChange = disableFrameChange;
        this.elementsSlider = this.getArrayRange(elements);
        if (customFormatValueHandle) this.formatValueHandle = customFormatValueHandle;
        this.formatValue = this.formatValueHandle(String(correctInitValue));
        if (!this.disableFrameChange) this.setValueToFrame();

        makeAutoObservable(this);
    }

    getArrayRange = (elements: IElement[]) =>
        elements?.reduce((acc, item, index) => {
            if (index === 0) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                acc.min = [item?.value, item?.step];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                acc.max = [item?.value, item?.step];
                return acc;
            }
            if (index === elements.length - 1) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                acc.max = [item?.value, item?.step];
                return acc;
            }
            acc[`${item.percent}%`] = [item?.value, item?.step];
            return acc;
        }, {}) ?? {};

    handleChangeConfig = ({ elements }: { elements: IElement[] }) => {
        this.elements = elements;
        this.maxValue = this.getMaxValue(elements);
        this.minValue = this.getMinValue(elements);
        this.elementsSlider = this.getArrayRange(elements);
        this.value = this.currentValue(String(this.value));
        this.formatValue = this.formatValueHandle(String(this.value));
    };

    handleChangeMinValue = ({ value }: { value: number }) => {
        this.minValue = value;

        if (this.value < value) {
            this.handleChangeValue(value.toString());
        }
    };

    handleChangeMaxValue = ({ value }: { value: number }) => {
        this.maxValue = value;

        if (this.value > value) {
            this.handleChangeValue(value.toString());
        }
    };

    handleChangeValue = (newValue: string) => {
        const numberRegex = /^\d*$/;

        if (!numberRegex.test(newValue)) return;

        const valueInGap = this.getValueInGap(newValue);

        this.value = +valueInGap;
        if (this.syncWithCookies)
            document.cookie = `creditAmount=${valueInGap}; path=/; expires=Fri, 31 Dec 9999 00:00:00 GMT;`;

        this.formatValue = this.formatValueHandle(`${valueInGap}`);
        if (!this.disableFrameChange) this.setValueToFrame();
    };

    handleChangeFocus = (status: boolean) => {
        this.isFocus = status;
        if (!status) this.validateValue();
    };

    getValueInGap = (value: string) => {
        if (+value > this.maxValue) return this.maxValue.toString();
        if (+value < this.minValue) return this.minValue.toString();
        return value;
    };

    validateValue = () => {
        if (this.value > this.maxValue) {
            this.value = this.maxValue;
            this.formatValue = this.formatValueHandle(`${this.maxValue}`);
        }
        if (this.value < this.minValue) {
            this.value = this.minValue;
            this.formatValue = this.formatValueHandle(`${this.minValue}`);
        }
    };

    getInitValueWithCookie = (initValue: number): number => {
        const cookieValue = +Cookie.getCookie('creditAmount');

        if (cookieValue < this.minValue || cookieValue > this.maxValue || Number.isNaN(cookieValue)) {
            document.cookie = `creditAmount=${initValue}; path=/; expires=Fri, 31 Dec 9999 00:00:00 GMT;`;
            return initValue;
        }
        return cookieValue;
    };

    currentValue = (value: string): number => {
        // @todo: вынести в хелперы parseValue
        // eslint-disable-next-line radix
        const parseValue = parseInt(value?.replace(/[^\d]/g, ''));
        if (parseValue > this.maxValue) {
            return this.maxValue;
        }
        if (parseValue < this.minValue) {
            return this.minValue;
        }
        return parseValue;
    };

    getMaxValue = (elements: IElement[]) => Math.max(...elements.map(item => item.value));

    getMinValue = (elements: IElement[]) => Math.min(...elements.map(item => item.value));

    // Установка значения в форму, в случае наличия
    setValueToFrame = () => {
        if (typeof window === 'undefined') return;
        if (window?.PBSDK && window?.PBSDK?.creditIssue) window.PBSDK.creditIssue.setCreditAmount(this.value);
    };

    // @todo: вынести в хелперы formatValueHandle
    formatValueHandle = (value: string, symbol = '₽') => `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${symbol}`;
}

export default RangeInput;
