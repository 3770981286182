import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import HypothecCalculatorContent from '@/components/blocks/calculators/layouts/HypothecCalculator/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import HypothecCalculatorStore from '@/domain/calculator/HypothecCalculatorStore';
import Feature from '@/domain/feature/Feature';
import { THypothecCalculator } from '@/types/calculator/hypothec';

const HypothecCalculator: React.FC<THypothecCalculator> = observer(({ title, config }) => {
    if (!config || config?.length <= 0) {
        return null;
    }

    useEffect(() => {
        const element = document.querySelector('.layoutMiddle');
        if (element) {
            element.classList.add('disable-overflow');
        }
    }, []);

    useEffect(() => {
        HypothecCalculatorStore.init(
            config[0].depositElements,
            config[0].sumElements,
            config[0].termsElements,
            config[0].rates[0].regions[0].rateValue
        );
    }, []);

    if (!Feature.isFeatureEnabled('enableHypothecCalculator')) {
        return null;
    }

    return (
        <CalculatorStoreContext.Provider value={HypothecCalculatorStore}>
            {HypothecCalculatorStore.initialized && <HypothecCalculatorContent title={title} config={config} />}
        </CalculatorStoreContext.Provider>
    );
});

export default withBlockLinks(HypothecCalculator);
