import React from 'react';

import cn from './style.module.sass';

function IconEllipse() {
    return (
        <div className={cn.icon}>
            <svg
                className={cn.svg}
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="11.5" cy="11.5" r="9.5" stroke="#DFDFDF" strokeWidth="4" className={cn.svgStroke} />
            </svg>
        </div>
    );
}

export default IconEllipse;
