import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    type?: 'question' | 'warning';
    color?: string;
}

const TooltipIcon: React.FC<IProps> = ({ type = 'warning', color }) => {
    if (type === 'question') {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={color && cn.color}
            >
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M5.83898 5.88261C6.00355 5.41477 6.32838 5.02028 6.75594 4.769C7.18351 4.51772 7.6862 4.42586 8.175 4.50971C8.6638 4.59355 9.10715 4.84767 9.42653 5.22708C9.74591 5.60648 9.92072 6.08667 9.91998 6.58261C9.91998 7.69394 8.5967 8.36418 8.051 8.59333C7.90926 8.65285 7.96172 9.06231 7.96172 9.06231M8 11.5H8.007M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                    stroke="#616680"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={color && cn.color}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
                className={clsx(cn.svgFillWhite, color && color)}
            />
            <path
                d="M14.2 17.8C14.2 18.4627 13.6627 19 13 19C12.3372 19 11.8 18.4627 11.8 17.8C11.8 17.1373 12.3372 16.6 13 16.6C13.6627 16.6 14.2 17.1373 14.2 17.8Z"
                className={clsx(cn.svgFillDark, color && color)}
            />
            <rect x="11.8" y="7" width="2.4" height="8.4" rx="1.2" className={clsx(cn.svgFillDark, color && color)} />
        </svg>
    );
};

export default TooltipIcon;
