import React from 'react';

interface IProps {
    tooltipText: string;
}

function TooltipText({ tooltipText }: IProps) {
    return <div className="promoTooltipText" dangerouslySetInnerHTML={{ __html: tooltipText }} />;
}

export default TooltipText;
