import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line import/no-named-default
import { default as NoUISlider } from 'nouislider-react';
import React, { useState } from 'react';

import Pips from '@/components/blocks/calculators/range/Pips';
import { IRangeInput } from '@/domain/calculator/blocksStore/Range';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    gtag?: GTagEvent;
    config: IRangeInput;
    callback?: () => void;
    customClass?: string;
    withPips?: boolean;
    minValue?: number;
    maxValue?: number;
    isRedesign?: boolean;
}
const FormedRangeNew: React.FC<IProps> = observer(
    ({ gtag, config, callback, customClass, withPips = true, minValue, maxValue, isRedesign }) => {
        const { elements, value, elementsSlider, handleChangeValue } = config;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const currentValue = data => +data[0];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const [ref, setRef] = useState(null);

        return (
            <div
                onClick={() => {
                    if (gtag?.action) event(gtag);
                }}
                role="presentation"
            >
                <NoUISlider
                    instanceRef={instance => {
                        if (instance && !ref) {
                            setRef(instance);
                        }
                    }}
                    start={value ?? 0}
                    behaviour="tap"
                    /* eslint-disable-next-line no-shadow */
                    onSlide={value => {
                        handleChangeValue(currentValue(value));
                        if (callback !== undefined) {
                            callback();
                        }
                    }}
                    /* eslint-disable-next-line no-shadow */
                    onChange={(value, handle) => {
                        handleChangeValue(currentValue(value));
                        if (callback !== undefined) {
                            callback();
                        }

                        if (value[handle] <= minValue) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                            ref?.noUiSlider.set(minValue);

                            handleChangeValue(minValue);
                        }

                        if (value[handle] >= maxValue) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                            ref?.noUiSlider.set(maxValue);

                            handleChangeValue(maxValue);
                        }
                    }}
                    connect={[true, false]}
                    className={clsx(cn.slider, customClass, isRedesign && cn.creditSlider)}
                    range={elementsSlider}
                    keyboardSupport={false}
                    animate={false}
                />
                {withPips && <Pips filterElements={elements} handleChange={handleChangeValue} callback={callback} />}
            </div>
        );
    }
);

export default FormedRangeNew;
