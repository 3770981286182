import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    customClass?: string;
    persent?: number;
}

function IconEllipse({ customClass, persent }: IProps) {
    const LENGTH = 60;

    const first = (LENGTH * persent) / 100;
    const second = (LENGTH * (100 - persent)) / 100;

    return (
        <div className={clsx(cn.icon, customClass)}>
            <svg
                className={cn.svg}
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="11.5"
                    cy="11.5"
                    r="9.5"
                    stroke="#3A499D"
                    strokeWidth="4"
                    strokeDasharray={`${first} ${second}`}
                    strokeDashoffset="15"
                    className={cn.svgStroke}
                />
            </svg>
        </div>
    );
}

export default IconEllipse;
