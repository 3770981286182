import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IHypothecCalculatorStore } from '@/domain/calculator/HypothecCalculatorStore';
import { THypothecCalculatorConfigItem } from '@/types/calculator/hypothec';
import { finFormattingValue } from '@/utils/finFormattingValue';

import cn from './style.module.sass';

interface IProps {
    currentConfig: THypothecCalculatorConfigItem;
}

function HypothecCalculatorInfo({ currentConfig }: IProps) {
    const { termValue, creditValue, getRate, paymentValue, depositValue, getDataChart } =
        useContext<IHypothecCalculatorStore>(CalculatorStoreContext);

    const monthlyPersentSum = getDataChart().reduce((acc, item) => acc + item.monthlyPersent, 0);
    const monthlyDebtSum = getDataChart().reduce((acc, item) => acc + item.monthlyDebt, 0);

    const formatValue = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return (
        <div className={cn.info}>
            <div className={cn.infoItem}>
                <div className={cn.infoItemTitle}>{currentConfig.target}</div>
                <div className={cn.infoItemText}>{getRate()}%</div>
            </div>
            <div className={cn.infoItem}>
                <div className={cn.infoItemTitle}>{getDataChart().length - 1} мес.</div>
                <div className={cn.infoItemText}>Срок кредита</div>
            </div>
            <div className={cn.infoItem}>
                <div className={cn.infoItemTitle}>{`${finFormattingValue(
                    creditValue - depositValue > 0 ? creditValue - depositValue : 0
                )} ₽`}</div>
                <div className={cn.infoItemText}>Сумма кредита</div>
            </div>
            <div className={cn.infoItem}>
                <div className={cn.infoItemTitle}>
                    {`${formatValue(
                        +(monthlyDebtSum + monthlyPersentSum - (creditValue - depositValue)).toFixed(2)
                    )} ₽`}
                </div>
                <div className={cn.infoItemText}>Переплата</div>
            </div>
        </div>
    );
}
export default observer(HypothecCalculatorInfo);
