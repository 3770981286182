import { THypothecCalculator } from '@/types/calculator/hypothec';

export const depositRegionsList = [
    'Москва',
    'Московская область',
    'Санкт-Петербург',
    'Ленинградская область',
    'Москва и Московская область',
    'Санкт-Петербург и Ленинградская область',
];

export const depositRatesList = ['Льготная ипотека', 'Семейная ипотека', 'Строительство дома в ДФО'];

export const hypothecCalculatorData: THypothecCalculator = {
    _template: 'hypothecCalculator',
    title: 'Ипотечный калькулятор',
    config: [
        {
            target: 'Ипотечный калькулятор',
            regionsMax: 12000000,
            regionsMin: 6000000,
            minCreditValue: 500000,
            regionsWithBigCredit: ['Москва', 'Московская область', 'Санкт-Петербург', 'Ленинградская область'],
            rates: [
                {
                    title: 'Льготная ипотека',
                    regionsMax: 6000000,
                    regionsMin: 6000000,
                    firstPayment: 30,
                    desc:
                        'Для граждан РФ, которые хотят построить дом, не являются и не являлись: заёмщиком, созаёмщиком, поручителем по: ' +
                        'любой государственной льготной ипотечной программе, оформленной с 23.12.2023; по программе Льготная ипотека, оформленной с 28.12.2022',
                    regions: [
                        {
                            label: 'Москва и Московская область',
                            value: 'Москва и Московская область',
                            rateValue: 8,
                            fullCreditCoastMin: 8.151,
                            fullCreditCoastMax: 9.909,
                        },
                        {
                            label: 'Санкт-Петербург и Ленинградская область',
                            value: 'Санкт-Петербург и Ленинградская область',
                            rateValue: 8,
                            fullCreditCoastMin: 8.151,
                            fullCreditCoastMax: 9.909,
                        },
                        {
                            label: 'Дальний Восток',
                            value: 'Дальний Восток',
                            rateValue: 8,
                            fullCreditCoastMin: 8.151,
                            fullCreditCoastMax: 9.909,
                        },
                        {
                            label: 'Другие регионы',
                            value: 'Другие регионы',
                            rateValue: 8,
                            fullCreditCoastMin: 8.151,
                            fullCreditCoastMax: 9.909,
                        },
                    ],
                },
                {
                    title: 'Семейная ипотека',
                    firstPayment: 20,
                    regionsMax: 12000000,
                    regionsMin: 6000000,
                    desc:
                        'Для граждан РФ, которые хотят построить дом у которых есть дети: родившиеся (усыновленные) в период с 01.01.2018 по 31.12.2023; ' +
                        'либо с инвалидностью в возрасте до 18 лет; либо два и более в возрасте до 18 лет',
                    regions: [
                        {
                            label: 'Москва и Московская область',
                            value: 'Москва и Московская область',
                            rateValue: 6,
                            fullCreditCoastMin: 5.959,
                            fullCreditCoastMax: 7.734,
                        },
                        {
                            label: 'Санкт-Петербург и Ленинградская область',
                            value: 'Санкт-Петербург и Ленинградская область',
                            rateValue: 6,
                            fullCreditCoastMin: 5.959,
                            fullCreditCoastMax: 7.734,
                        },
                        {
                            label: 'Другие регионы',
                            value: 'Другие регионы',
                            rateValue: 6,
                            fullCreditCoastMin: 5.964,
                            fullCreditCoastMax: 7.734,
                        },
                    ],
                },
                {
                    title: 'Строительство дома в ДФО',
                    firstPayment: 20,
                    regionsMax: 6000000,
                    regionsMin: 6000000,
                    desc:
                        'Для семей Дальнего Востока, которые хотят построить дом у которых есть дети: родившиеся (усыновленные) в период с 01.01.2018 по 31.12.2023; ' +
                        'либо с инвалидностью в возрасте до 18 лет; либо два и более в возрасте до 18 лет',
                    regions: [
                        {
                            label: 'Дальний Восток',
                            value: 'Дальний Восток',
                            rateValue: 5,
                            fullCreditCoastMin: 4.973,
                            fullCreditCoastMax: 6.881,
                        },
                    ],
                },
            ],
            depositElements: [
                { value: 0, step: 100000, percent: 0, label: '0', id: 1 },

                { value: 100000000, step: 500000, percent: 100, label: '100 млн', id: 8 },
            ],
            sumElements: [
                { value: 0, step: 500000, percent: 0, label: '0', id: 110 },

                { value: 100000000, step: 500000, percent: 100, label: '100 млн', id: 100 },
            ],
            termsElements: [
                { value: 1, step: 1, percent: 0, label: '1 год', id: 1 },
                { value: 5, step: 1, percent: 16, label: '5 лет', id: 2 },
                { value: 10, step: 1, percent: 33, label: '10 лет', id: 3 },
                { value: 15, step: 1, percent: 50, label: '15 лет', id: 4 },
                { value: 20, step: 1, percent: 66, label: '20 лет', id: 5 },
                { value: 25, step: 1, percent: 83, label: '25 лет', id: 6 },
                { value: 30, step: 1, percent: 100, label: '30 лет', id: 7 },
            ],
            matCapitalDesc: '',
            button: { label: 'Онлайн заявка', link: '#' },
            tooltipText: 'Если Вы хотите взять кредит на большую сумму, обратитесь к менеджеру.',
        },
    ],
};
