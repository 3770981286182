import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import Counter from '@/components/blocks/calculators/layouts/HypothecCalculator/Counter';
import HypothecCalculatorModalContent from '@/components/blocks/calculators/layouts/HypothecCalculator/ModalContent';
import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import ModalWrapper from '@/components/modal/ModalWrapper';
import CustomTooltip from '@/components/Tooltip';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import CustomSelect from '@/components/UI/CustomSelect';
import Title from '@/components/UI/Title';
import TooltipIcon from '@/components/UI/TooltipIcon';
import TooltipText from '@/components/UI/TooltipText';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { useCity } from '@/context/CityProvider';
import { depositRatesList, depositRegionsList } from '@/data/blocks/calculators/hypothec';
import { IHypothecCalculatorStore } from '@/domain/calculator/HypothecCalculatorStore';
import {
    THypothecCalculatorConfigItem,
    THypothecCalculatorConfigRatesItem,
    THypothecCalculatorConfigRegionsItem,
    THypothecCalculatorContent,
} from '@/types/calculator/hypothec';
import { ISelectElement } from '@/types/select';
import { finFormattingValue } from '@/utils/finFormattingValue';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const HypothecCalculatorContent: FC<THypothecCalculatorContent> = observer(({ title, config }) => {
    const {
        termsElements,
        depositElements,
        sumElements,
        creditValue,
        getRate,
        handleChangeRate,
        paymentValue,
        toggleChecked,
        insurancesElements,
        toggleSpecialActive,
        handleChangeAmountRepayment,
        handleChangeFrequency,
        handleChangeCell,
        isSpecialActive,
        getNalogSum,
        handleChangeDepositElementsMinValue,
        handleChangeDepositElementsMaxValue,
        handleChangeSumElementsMinValue,
        termValue,
        depositValue,
        configParams,
    } = useContext<IHypothecCalculatorStore>(CalculatorStoreContext);

    const { currentCity } = useCity();
    const [currentConfig, setCurrentConfig] = useState<THypothecCalculatorConfigItem>(config[0]);

    const [currentRatesItem, setCurrentRatesItem] = useState<THypothecCalculatorConfigRatesItem>(config[0].rates[0]);

    const [minDepositValue, setMinDepositValue] = useState((creditValue * currentRatesItem.firstPayment) / 100);

    const [maxDepositValue, setMaxDepositValue] = useState(creditValue * 0.9);

    const [currentMinCreditValue, setCurrentMinCreditValue] = useState<number>(null);

    const [regionLimit, setRegionLimit] = useState(currentConfig.regionsMin);

    const [typeProgramValue, setTypeProgramValue] = useState({
        label: currentConfig.rates[0].title,
        value: currentConfig.rates[0].title,
        desc: currentConfig.rates[0].desc,
    });

    const optionsRates = [];
    currentConfig.rates.forEach(item => {
        optionsRates.push({ label: `${item.title}`, value: item.title, desc: item?.desc });
    });

    const [regionValue, setRegionValue] = useState<THypothecCalculatorConfigRegionsItem>(
        currentConfig.rates[0].regions[0]
    );

    const [currentRegionLimit, setCurrentRegionLimit] = useState(currentConfig.regionsMax);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpen = () => {
        setIsModalOpen(true);
    };

    const needFieldFullCoast = useMemo(() => {
        if (!regionValue?.fullCreditCoastMin || !regionValue?.fullCreditCoastMax) {
            return false;
        }
        return true;
    }, [regionValue]);

    const depositRegionsFlag = useMemo(() => depositRegionsList.includes(regionValue.value), [regionValue]);

    const depositRatesFlag = useMemo(() => depositRatesList.includes(currentRatesItem.title), [currentRatesItem]);

    useEffect(() => {
        // Максимально возможная сумма ипотеки в зависимости от региона и выбранной программы

        const value = depositRegionsFlag ? currentRatesItem.regionsMax : currentRatesItem.regionsMin;
        setRegionLimit(value);
    }, [currentRatesItem, regionValue]);

    useEffect(() => {
        // Минимально возможная сумма кредита
        const currentMinValue = currentConfig?.minCreditValue || 500000;

        // Минимально возможная стоимость недвижимости
        const tempMinCreditValue = Math.round(
            currentMinValue +
                (currentMinValue * (currentRatesItem.firstPayment / 100)) /
                    ((100 - currentRatesItem.firstPayment) / 100)
        );

        handleChangeSumElementsMinValue(tempMinCreditValue);
        setCurrentMinCreditValue(tempMinCreditValue);
    }, [currentConfig.minCreditValue, currentRatesItem]);

    useEffect(() => {
        // Максимально возможная сумма первоначального взноса - чтобы сумма ипотеки составила хотя бы 500 000 - minCreditValue

        const maxCreditValue = creditValue - (currentConfig?.minCreditValue || 500000);

        if (maxDepositValue < maxCreditValue || maxDepositValue >= creditValue) {
            setMaxDepositValue(maxCreditValue);
            handleChangeDepositElementsMaxValue(maxCreditValue);
        }
    }, [creditValue, currentConfig?.minCreditValue]);

    useEffect(() => {
        // Минимально возможная сумма первоначального взноса в зависимости от суммы ипотеки -
        // заданный процент, либо чтобы сумма кредита не превысила лимит по регионам

        if (!depositRatesFlag) return;

        const tempMinDeposit = Math.round(creditValue * (currentRatesItem.firstPayment / 100));

        if (creditValue <= currentMinCreditValue) {
            // 500 000 - minCreditValue - минимально возможная сумма кредита

            handleChangeDepositElementsMinValue(tempMinDeposit);
            setMinDepositValue(tempMinDeposit);
            setMaxDepositValue(tempMinDeposit);
            handleChangeDepositElementsMaxValue(tempMinDeposit);
        } else if (tempMinDeposit > creditValue - regionLimit) {
            handleChangeDepositElementsMinValue(tempMinDeposit);
            setMinDepositValue(tempMinDeposit);
        } else {
            handleChangeDepositElementsMinValue(creditValue - regionLimit);
            setMinDepositValue(creditValue - regionLimit);
        }

        setCurrentRegionLimit(regionLimit / 1000000);
    }, [creditValue, depositValue, depositRegionsFlag, depositRatesFlag, currentRatesItem]);

    const uuidUser = useMemo(() => v4(), []);

    const specialLink = `https://my.pochtabank.ru/pos-credit-v2?operId=${uuidUser}&productCode=${
        depositRatesFlag ? `MORTGAGE_${regionValue.rateValue}_${currentRegionLimit}_IGS` : 'EXP_DOM_PP'
    }&toCode=9999999998&ttCode=999999999821&ttName=&amountCredit&termCredit=${
        termValue * 12
    }&firstPayment=${depositValue}&fullName=&phone=&brokerAgentId=NON_BROKER&returnUrl=&order%5B0%5D%5Bcategory%5D=274&order%5B0%5D%5Bmark%5D=%D0%94%D0%BE%D0%BC&order%5B0%5D%5Bmodel%5D=%D0%9A%D0%B8%D1%80%D0%BF%D0%B8%D1%87%D0%BD%D1%8B%D0%B9&order%5B0%5D%5Bquantity%5D=1&order%5B0%5D%5Bprice%5D=${creditValue}&_ga=2.48246177.646290470.1639584009-169232854.1638538784`;

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={cn.content}>
                    <div className={cn.left}>
                        <div className={cn.wrapper}>
                            <Title title={currentConfig.target} level={2} />
                            <div className={cn.ratesWrapper}>
                                <div className={cn.wrapperTop}>Тип программы</div>
                                <CustomSelect
                                    placeholder="Тип программы"
                                    value={typeProgramValue}
                                    options={optionsRates}
                                    customOptionFlag
                                    onChange={(e: ISelectElement<string>) => {
                                        setTypeProgramValue({
                                            label: e.label,
                                            value: e.value,
                                            desc: e?.desc,
                                        });

                                        const current = currentConfig.rates.find(item => item.title === e.label);

                                        setRegionValue(current.regions[0]);

                                        setCurrentRatesItem(current);

                                        handleChangeRate(current.regions[0].rateValue);
                                    }}
                                />
                            </div>
                            <div className={cn.regionWrapper}>
                                <div className={cn.wrapperTop}>Регион</div>
                                <CustomSelect
                                    placeholder="Регион"
                                    value={regionValue}
                                    onChange={(e: THypothecCalculatorConfigRegionsItem) => {
                                        setRegionValue({
                                            label: e.label,
                                            value: e.value,
                                            rateValue: e.rateValue,
                                            fullCreditCoastMin: e.fullCreditCoastMin,
                                            fullCreditCoastMax: e.fullCreditCoastMax,
                                        });
                                        handleChangeRate(e.rateValue);
                                    }}
                                    options={currentRatesItem.regions}
                                />
                            </div>

                            <div className={cn.firstPayment}>
                                <span>Стоимость недвижимости</span>

                                {currentConfig?.tooltipText && (
                                    <div className={cn.tooltip}>
                                        <CustomTooltip html={<TooltipText tooltipText={currentConfig.tooltipText} />}>
                                            <TooltipIcon />
                                        </CustomTooltip>
                                    </div>
                                )}
                            </div>
                            <RangeInput
                                config={sumElements}
                                minValue={currentConfig?.minCreditValue || 500000}
                                noMaxWidth
                            />
                            <div className={cn.mark}>
                                Вы можете взять кредит до
                                {` ${finFormattingValue(
                                    depositRegionsFlag && currentRatesItem.title !== 'Льготная ипотека'
                                        ? currentConfig.regionsMax
                                        : currentConfig.regionsMin
                                )}`}
                                &nbsp;₽
                            </div>

                            <div className={cn.firstPayment}>
                                Первоначальный взнос от {currentRatesItem.firstPayment}%
                            </div>
                            <RangeInput
                                config={depositElements}
                                minValue={minDepositValue}
                                maxValue={maxDepositValue}
                                noMaxWidth
                            />
                            <div className={cn.mark}>
                                Вы можете выбрать сумму первоначального взноса от
                                {` ${finFormattingValue(minDepositValue)}`}
                                &nbsp;₽
                            </div>

                            <RangeInput config={termsElements} noMaxWidth />
                            {insurancesElements && insurancesElements?.length > 0 && (
                                <div className={cn.bottom}>
                                    {insurancesElements.map((item, index) => (
                                        <div key={index} className={cn.bottomRow}>
                                            <Checkbox
                                                label={item.title}
                                                checked={item.isActive || false}
                                                onChangeHandler={() => toggleChecked(item.title)}
                                                customClass={clsx(cn.checkbox, 'hypothecCheckbox')}
                                            />
                                            {item.desc && (
                                                <div className={cn.tooltipWrapper}>
                                                    <CustomTooltip
                                                        html={<div className={cn.tooltipText}>{item.desc}</div>}
                                                    >
                                                        <span className={cn.tooltip}>
                                                            <TooltipIcon />
                                                        </span>
                                                    </CustomTooltip>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={cn.right}>
                        <div className={cn.rightWrapper}>
                            <div className={cn.wrapResult}>
                                <div className={cn.row}>
                                    <div className={clsx(cn.rowItem, cn.rowItem70)}>
                                        <div className={cn.resultText}>Сумма ежемесячного платежа</div>
                                        <div className={cn.resultNumber}>
                                            <Counter
                                                paymentValue={finFormattingValue(Math.round(paymentValue))}
                                                field={termsElements}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={cn.row}>
                                    <div className={cn.rowItem}>
                                        <div className={cn.resultText}>Сумма кредита</div>
                                        <div
                                            className={cn.resultNumber}
                                            dangerouslySetInnerHTML={{
                                                __html: `${finFormattingValue(
                                                    creditValue - depositValue > 0 ? creditValue - depositValue : 0
                                                )}&nbsp;₽`,
                                            }}
                                        />
                                    </div>
                                    <div className={clsx(cn.rowItem, cn.rowItem30)}>
                                        <div className={cn.resultText}>Ставка</div>
                                        <div className={cn.resultNumber}>{getRate()}%</div>
                                    </div>
                                </div>

                                {needFieldFullCoast && (
                                    <div className={cn.row}>
                                        <div className={clsx(cn.rowItem)}>
                                            <div className={cn.resultText}>Полная стоимость кредита (диапазон)</div>
                                            <div className={cn.resultNumber}>
                                                {regionValue.fullCreditCoastMin.toFixed(3)}% -{' '}
                                                {regionValue.fullCreditCoastMax.toFixed(3)}%
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className={cn.row}>
                                    <div className={cn.rowItem}>
                                        <div className={cn.resultText}>Совокупный доход</div>
                                        <div
                                            className={cn.resultNumber}
                                            dangerouslySetInnerHTML={{
                                                __html: `${finFormattingValue(
                                                    Math.round((paymentValue * 100) / 60)
                                                )}&nbsp;₽`,
                                            }}
                                        />
                                    </div>
                                    <div className={clsx(cn.rowItem, cn.rowItem40)}>
                                        <div className={cn.resultText}>Налоговый вычет</div>
                                        <div
                                            className={cn.resultNumber}
                                            dangerouslySetInnerHTML={{
                                                __html: `${finFormattingValue(Math.round(getNalogSum()))}&nbsp;₽`,
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className={cn.buttonWrapper}>
                                    <Button
                                        variant="btnBlue"
                                        type="link"
                                        href={specialLink || currentConfig.button.link}
                                        label={currentConfig.button.label}
                                        size="small"
                                        customClass={cn.button}
                                        onClick={() => {
                                            if (currentConfig?.gtag?.action) event(currentConfig.gtag);
                                        }}
                                        animation={false}
                                    />

                                    <Button
                                        variant="btnWhite"
                                        type="button"
                                        label="График платежей"
                                        img="/img-next/svg/chart.svg"
                                        customClass={clsx(cn.button, cn.buttonBlack, 'full')}
                                        animation={false}
                                        onClick={() => setIsModalOpen(true)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleClose={() => {
                    setIsModalOpen(false);
                    if (isSpecialActive) {
                        toggleSpecialActive();
                        handleChangeCell('');
                        handleChangeFrequency('');
                        handleChangeAmountRepayment(0);
                    }
                }}
                handleOpen={handleOpen}
                wrapperClassName="modalBigWrapper"
                bgClassName={cn.modalOverlay}
                className={cn.modalInner}
                clickOutsideExceptionsId={['modalWrapper']}
            >
                <HypothecCalculatorModalContent currentConfig={currentConfig} />
            </ModalWrapper>
        </div>
    );
});

export default HypothecCalculatorContent;
