import { observer } from 'mobx-react-lite';
import React from 'react';

import PipNew from '@/components/blocks/calculators/range/Pip';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';

import cn from './style.module.sass';

interface IProps {
    filterElements: ICalculatorFilterElement[];
    handleChange: (number) => void;
    callback?: () => void;
}

const Pips: React.FC<IProps> = observer(({ filterElements, handleChange, callback }) => (
    <div className={cn.pips}>
        <div className={cn.pipsContainer}>
            {filterElements?.map((filterElement: ICalculatorFilterElement) => (
                <PipNew
                    key={filterElement.id}
                    filterElement={filterElement}
                    handleChange={handleChange}
                    callback={callback}
                />
            ))}
        </div>
    </div>
));

export default Pips;
