// eslint-disable-next-line import/no-duplicates
// eslint-disable-next-line import/no-duplicates
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line import/no-named-default
import { default as NoUISlider } from 'nouislider-react';
import React, { useContext, useEffect, useState } from 'react';

import IconEllipse from '@/components/UI/IconEllipse';
import IconEllipseTop from '@/components/UI/IconEllipseTop';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IDataItem, IHypothecCalculatorStore } from '@/domain/calculator/HypothecCalculatorStore';
import { THypothecCalculatorConfigItem } from '@/types/calculator/hypothec';

import cn from './style.module.sass';

interface IProps {
    currentConfig: THypothecCalculatorConfigItem;
}

function HypothecCalculatorMonthInfo({ currentConfig }: IProps) {
    const { getDataChart, paymentValue } = useContext<IHypothecCalculatorStore>(CalculatorStoreContext);

    const formatValue = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const [isCurrentMonth, setIsCurrentMonth] = useState<IDataItem>(getDataChart()[1]);

    const [dataSlider, setDataSlider] = useState<Array<string>>(null);

    useEffect(() => {
        const data = getDataChart();

        const tempData = [];

        data.forEach((item, index) => {
            if (index !== 0) {
                tempData.push(item.date);
            }
        });

        setDataSlider(tempData);
    }, [getDataChart().length]);

    const format = {
        to(value) {
            return dataSlider[Math.round(value)];
        },
        from(value) {
            return dataSlider.indexOf(value);
        },
    };

    if (paymentValue <= 0) return null;

    return (
        <div className={cn.wrapper}>
            <div className={cn.info}>
                <div className={cn.infoItem}>
                    <div className={cn.infoItemTitle}>{`${formatValue(isCurrentMonth?.monthlyPayment)} ₽`}</div>
                    <div className={cn.infoItemText}>Ежемесячный платеж</div>
                </div>
                <div className={cn.infoItem}>
                    <div className={cn.infoItemTitle}>
                        <div className={cn.infoItemIcon}>
                            <IconEllipse />
                            <IconEllipseTop
                                customClass={cn.infoItemIconTop}
                                persent={(isCurrentMonth.monthlyDebt * 100) / isCurrentMonth.monthlyPayment}
                            />
                        </div>
                        {`${formatValue(isCurrentMonth?.monthlyDebt)} ₽`}
                    </div>
                    <div className={cn.infoItemText}>Основной долг</div>
                </div>
                <div className={cn.infoItem}>
                    <div className={cn.infoItemTitle}>
                        <div className={cn.infoItemIcon}>
                            <IconEllipse />
                            <IconEllipseTop
                                customClass={cn.infoItemIconTop}
                                persent={(isCurrentMonth.monthlyPersent * 100) / isCurrentMonth.monthlyPayment}
                            />
                        </div>
                        {`${formatValue(isCurrentMonth?.monthlyPersent)} ₽`}
                    </div>
                    <div className={cn.infoItemText}>Проценты</div>
                </div>
                <div className={cn.infoItem}>
                    <div className={cn.infoItemTitle}>
                        <div className={cn.infoItemIcon}>
                            <IconEllipse />
                            <IconEllipseTop customClass={cn.infoItemIconTop} persent={65} />
                        </div>
                        {`${formatValue(isCurrentMonth?.monthlyBalance)} ₽`}
                    </div>
                    <div className={cn.infoItemText}>Остаток долга</div>
                </div>
            </div>

            {dataSlider && (
                <div className={cn.sliderWrapper}>
                    <NoUISlider
                        start={dataSlider[0]}
                        range={{ min: 0, max: dataSlider.length - 1 }}
                        step={1}
                        tooltips
                        format={format}
                        className={cn.slider}
                        onChange={value => {
                            const elem = getDataChart().filter(item => item.date === value[0])[0];

                            setIsCurrentMonth(elem);
                        }}
                    />

                    <div className={cn.sliderPips}>
                        <div className={cn.sliderPipsItem}>{getDataChart()[1].yearValue}</div>

                        <div className={clsx(cn.sliderPipsItem, cn.sliderPipsItemInfo)}>{isCurrentMonth.date}</div>

                        <div className={cn.sliderPipsItem}>{getDataChart().pop().yearValue}</div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default observer(HypothecCalculatorMonthInfo);
