import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { DecSvg } from '@/components/blocks/calculators/layouts/BuildingCalculatorWithPicture/svg/DecSvg';
import { IncSvg } from '@/components/blocks/calculators/layouts/BuildingCalculatorWithPicture/svg/IncSvg';
import { IRangeInput } from '@/domain/calculator/blocksStore/Range';

import cn from './style.module.sass';

const Counter: FC<{ paymentValue: string; field: IRangeInput }> = observer(({ paymentValue, field }) => {
    const { handleChangeValue, value, maxValue, minValue } = field;

    const inc = () => {
        handleChangeValue(value + 1);
    };

    const dec = () => {
        handleChangeValue(value - 1);
    };

    return (
        <div className={cn.counterWrap}>
            <button type="button" className={cn.counterBtn} disabled={value === maxValue} onClick={inc}>
                <DecSvg color="#001E45" customClass={cn.counterBtnImg} />
            </button>
            <div className={clsx(cn.resultNumber)}>{`${paymentValue} ₽`}</div>
            <button type="button" className={cn.counterBtn} disabled={value === minValue} onClick={dec}>
                <IncSvg color="#001E45" customClass={cn.counterBtnImg} />
            </button>
        </div>
    );
});

export default Counter;
